import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'

const Contact = () => {
    return (
        <div className="contact">
            <h2>Get In Touch</h2>
            <div className="contact__options">
                <article className="contact__option">
                    < MdOutlineEmail className="contact__option-icon" />
                    <h4>My Email</h4>
                    <h5>dcdial20@gmail.com</h5>
                    <a href="mailto:dcdial20@gmail.com" target="_blank" rel="noopener noreferrer">Send a Message</a>
                </article>
                <article className="contact__option">
                    < AiFillLinkedin className="contact__option-icon" />
                    <h4>LinkedIn</h4>
                    <h5>Chase Dial</h5>
                    <a href="https://linkedin.com/in/chasedial" target="_blank" rel="noopener noreferrer">LinkedIn Message</a>
                </article>
                <article className="contact__option">
                    < AiFillGithub className="contact__option-icon" />
                    <h4>Github</h4>
                    <h5>cybernexchase</h5>
                    <a href="https://github.com/cybernexchase" target="_blank" rel="noopener noreferrer">Github Repositories</a>
                </article>
                <article className="contact__option">
                    < RiMessengerLine className="contact__option-icon" />
                    <h4>Messenger</h4>
                    <h5>Chase Dial</h5>
                    <a href="https://m.me/chase.dial" target="_blank" rel="noopener noreferrer">Send a Message</a>
                </article>
            </div>
            {/*
            <a href="mailto:dcdial20@gmail.com" target="_blank" className="button"><MdOutlineEmail /> dcdial20@gmail.com</a>
            <a href="https://linkedin.com/in/chasedial" target="_blank" className="button">Linkedin</a>
            <a href="https://github.com/cybernexchase" target="_blank" className="button">Github</a>
            <a href="https://instagram.com/cybernexchase" target="_blank" className="button">Instagram</a>
            */}
        </div>
    );
}

export default Contact;