const Experience = () => {
    return (
        <div className="experience">
            <h2>Experience</h2>
            {/* stopped here; finish experience section
                keep each position to 2 - 3 bullet points, super summarized */}
            <ul>
                <li>
                    <i>State of Tennessee</i> - <strong>Database Administrator / Data Analyst</strong>, 2016-Current
                    <ul>
                        <li>Serves as the main point of contact for the TN National Guard facilities maintenance requests database</li>
                        <li>Structure SQL queries to collect data for cost analysis; use Excel and Power BI to model the data</li>
                        <li>Intermediate to advanced functions and formulas in Excel data visualization: VLOOKUPS, Pivot Tables, Macros, IF statements</li>
                    </ul>
                </li>
                <li>
                    <i>Tennessee Army National Guard</i> - <strong>Captain / Engineer Officer / Assistant Operations Officer</strong>, 2007-Current
                    <ul>
                        <li>15 years of experience in mission/data analysis; data collection, analysis and visualization to use as actionable intelligence</li>
                        <li>10 years of experience in various leadership positions, ranging from a small team to a group of over 60 personnel</li>
                        <li>Volunteered for Tennessee's Tornado Disaster Relief (MAR 2020) and COVID-19 (APR - DEC 2020) missions</li>
                    </ul>
                </li>
                <li>
                    <i>Slayden Door and Hardware</i> - <strong>Project Manager</strong>, 2015-2016
                    <ul>
                        <li>Successfully coordinated the delivery of doors, frames and hardware for various sized commercial projects in the Middle Tennessee area</li>
                        <li>Reviewed project blueprints and documentation in order to give accurate cost estimates to the general contractor for bidding</li>
                        <li>Managed the billing for the projects, tracked change orders, and updated cost estimates</li>
                    </ul>
                </li>
            </ul>
            <h3>Education</h3>
            <div className="education__container">
                <div className="education">
                    <h4>B.S. in Business Administration</h4>
                    <h5>Tennessee Technological University</h5>
                    <h5 className="edu__dates"><i>2006 - 2011</i></h5>
                </div>
                <div className="education">
                    <h4>Army Engineer Basic Officer Leadership Course</h4>
                    <h5>Military School of Engineering</h5>
                    <h5 className="edu__dates">06/2011 - 10/2011</h5>
                </div>
                <div className="education">
                    <h4>Army Engineer Captains' Career Course</h4>
                    <h5>Military School of Engineering</h5>
                    <h5 className="edu__dates">07/2021 - 09/2021</h5>
                </div>
            </div>
        </div>
    );
}

export default Experience;