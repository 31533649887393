import React, { useState } from 'react';
// import compound from '../assets/compound.png';
// import aave from '../assets/aave.png';
import awsStructurethumb from '../assets/awsStructurethumb.png'
import awsStructure from '../assets/awsStructure.png'
import Popup from './Popup';


const Projects = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    return (
        <section className="projects">
            <h2>My Projects</h2>

            <div className="projects__cards">

                <div className="projects__card">
                    <h3>AWS structure</h3>
                    <img src={awsStructurethumb} alt="awsThumb" />
                    <p>My website is hosted with AWS. Click the Info button below to see more information about the structure & services.
                    </p>

                    <input
                        className="popup__button"
                        type="button"
                        value="Info"
                        onClick={togglePopup} />
                    {isOpen && <Popup
                        content={<>
                            <div className="aws__narrative">

                                <div className="structure__container">
                                    <img src={awsStructure} alt="awsStructure" className="structure__image" />
                                </div>

                                <div className="aws__text">
                                    <h4>My AWS Structure</h4>
                                    <h5>In the picture above you can see my AWS structure. </h5>
                                    <p className="services"><b>Services Used:</b><br />
                                        <br />
                                        <b>Route 53 - </b>Amazon's Domain Name System (DNS) web service; it routes visitors to this site, after searching for cybernexchase.com.<br />
                                        <br />
                                        <b>CloudFront - </b>Amazon's content delivery network (CDN); used to securely deliver my content from my S3 bucket to visitors.<br />
                                        <br />
                                        <b>AWS Certificate Manager (ACM) - </b>provisions and manages SSL certificates, providing a secure connection to my site.<br />
                                        <br />
                                        <b>Amazon S3 (Simple Storage Service) - </b>bucket used to store & protect my files/data for this website.<br />
                                        <br />
                                        As you can see from the diagram, visitors are routed to my website through Route 53 to CloudFront.<br />
                                        <br />
                                        At the same time, the ACM kicks in, activating my SSL certificate, securing the site and visitors' connections to it.<br />
                                        <br />
                                        CloudFront then sends a request to my S3 bucket to pull content from the files stored there. Once pulled, the content from the data is routed back to the visitor for them to see.<br />
                                    </p>
                                </div>



                            </div>
                        </>}
                        handleClose={togglePopup}
                    />}
                    <a href="https://github.com/cybernexchase" target="_blank" rel="noreferrer" className="button">Code</a>
                </div>

                {/* future project(s)
                <div className="projects__card">
                    <h3>Compound</h3>
                    <img src={compound} alt="Compound Landing Page" />
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis consectetur facere neque assumenda quasi aliquid,
                        perferendis ratione rerum dolorem.
                    </p>

                    <a href="https://..." target="_blank" rel="noreferrer" className="button">Site</a>
                    <a href="https://github.com/..." target="_blank" rel="noreferrer" className="button">Code</a>
                        </div> */}
            </div>
        </section>
    );
}

export default Projects;