// Import Assets
import profile from '../assets/profileimg2.png';
import resume from '../assets/Chase Dial Resume.pdf';
import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { RiMessengerLine } from 'react-icons/ri';

const Header = () => {
    return (
        <section className='header'>
            <img src={profile} alt="John Doe" />

            <div className='header__content'>
                <h1>Hi, I'm Chase Dial</h1>
                <p>Developer</p>
                <a href={resume} download className='button'>Download Resume</a>
                <div className='header__socials'>
                    <a href="https://linkedin.com/in/chasedial" target="_blank" rel="noopener noreferrer" className='header__socials-icon'>
                        <AiFillLinkedin />
                    </a>
                    <a href="https://github.com/cybernexchase" target="_blank" rel="noopener noreferrer" className='header__socials-icon'>
                        < AiFillGithub />
                    </a>
                    <a href="https://m.me/chase.dial" target="_blank" rel="noopener noreferrer" className='header__socials-icon'>
                        < RiMessengerLine />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Header;