const About = () => {
    return (
        <section className='about'>
            <h2>About Me</h2>
            <p>
                I am a self taught developer, with personal experience in HTML, CSS, JavaScript, React, Solidity, Ether.js, and Hardhat. As you can see on my site, I am constantly working on it by adding projects, trying out a new layout, and integrating Web 3 / blockchain functionality to it.<br />
                <br />
                I am also currently studying for the AWS Solutions Architect - Associate Exam. This site is one of my first projects working in the cloud as it is hosted by AWS using S3, Route53, CloudFront and ACM. Check out my first project to see more information about my current AWS structure.
            </p>
            <br />
            <h3>Professional Summary</h3>
            <p>
                <ul>
                    <li>
                        Skilled professional with 10 years of experience in management and leadership positions; able
                        to lead, mentor and build rapport in high-paced environments with little or no supervision
                    </li>
                    <br />
                    <li>
                        5 years of experience as a database administrator with an increasing focus on data analytics; responsible for managing & analyzing data
                        for 90 facilities statewide, creating user profiles, assigning roles, modeling data, and creating reports.
                    </li>
                    <br />
                    <li>
                        Certified Scrum Master with Effective communication skills, both oral and written, who is skilled at compiling reports,
                        writing memorandums and letters; comfortable working with all levels of management while performing well under pressure and excelling at multi-tasking and maintaining close attention to detail.
                    </li>
                </ul>
            </p>
        </section>
    );
}

export default About;